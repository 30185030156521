export default [
  {
    path: "/",
    name: "default",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/default"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      authRequired: false
    },
  },
  {
    path: "/category",
    name: "category",
    component: () => import("./views/categorys/category.vue"),
    meta: {
      authRequired: true
    },
  },
  {
    path: "/sub-category",
    name: "subCategory",
    component: () => import("./views/categorys/subCategory.vue"),
    meta: {
      authRequired: true
    },
  },
  {
    path: "/collections",
    name: "collection",
    component: () => import("./views/collections/main.vue"),
    meta: {
      authRequired: true
    },
  },
  {
    path: "/tags",
    name: "tags",
    component: () => import("./views/tags/main.vue"),
    meta: {
      authRequired: true
    },
  },
  {
    path: "/add-new-product",
    name: "addNewProduct",
    component: () => import("./views/products/addNewProduct.vue"),
    meta: {
      authRequired: true
    },
  },
  {
    path: "/edit-product/:productId",
    name: "editProduct",
    props: true,
    component: () => import("./views/products/editProduct.vue"),
    meta: {
      authRequired: true
    },
  },
  {
    path: "/view-all-product",
    name: "viewAllProduct",
    component: () => import("./views/products/viewAllProduct.vue"),
    meta: {
      authRequired: true
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: import("./views/pageNotFound.vue"),
    meta: {
      title: "Page not found",
    },
  },
];
