import { createStore } from 'vuex'

import modules from './modules'

const store = createStore({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
  state: {
    page: 1,
  },

  mutations: {
    setPage(state, newValue) {
      state.page = newValue
    }
  },

  getters: {

    getPage(state) {
      return state.page;
    }
  }
})

export default store

