export const CLIENT_URL = process.env.VUE_APP_URL;
export const BASE_URL = process.env.VUE_APP_SERVER_URL;

export const API_ROUTES = {
  AUTH: {
    LOGIN: "/auth/login",
    VALIDATE: "/auth/heartbeat",
  },
  TAGS: {
    CREATE_TAG: "/admin/tags",
    GET_ALL_TAG: "/admin/tags/getAllTag",
    DELETE_TAG: "/admin/tags",
    UPDATE_TAG: "/admin/tags",
  },
  COLLECTION: {
    CREATE: "/admin/collections",
    UPDATE: "/admin/collections",
    GETALL: "/admin/collections",
    DELETE: "/admin/collections",
  },
  CATEGORY: {
    CREATE: "/admin/category",
    UPDATE: "/admin/category",
    GETALL: "/admin/category",
    DELETE: "/admin/category",
  },
  SUB_CATEGORY: {
    CREATE: "/admin/sub-category",
    UPDATE: "/admin/sub-category",
    GETALL: "/admin/sub-category",
    DELETE: "/admin/sub-category",
  },
  PRODUCT: {
    CREATE: "/admin/products",
    GETALL: "/admin/products/getAllProductForBackoffice",
    GETBYID: "/admin/products",
    UPDATE: "/admin/products",
    DELETE: "/admin/products",
  },
  PRODUCT_MEDIA: {
    CREATE: "/productMedia",
    updateSequence: "/productMedia/updateSequence",
    delete: (productId, mediaId) => { return `/productMedia/product/${productId}/media/${mediaId}` }
  },
  TAG_MAPPING: {
    CREATE: "/admin/tagProductMapping",
    delete: (productId, mappingId) => { return `/admin/tagProductMapping/product/${productId}/tag/${mappingId}` }
  },
  COLLECTION_MAPPING: {
    CREATE: "/admin/collectionProductMapping",
    delete: (productId, mappingId) => { return `/admin/collectionProductMapping/product/${productId}/collection/${mappingId}` }
  }
};
