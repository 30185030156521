import axios from 'axios'
import { API_ROUTES, BASE_URL } from '../../constents/config.js'

export const state = {
    currentUser: sessionStorage.getItem('authUser'),
}

export const mutations = {
    SET_CURRENT_USER (state, newValue) {
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn (state) {
        return !!state.currentUser
    }
}

export const actions = {
    init ({ dispatch }) {
        dispatch('validate')
    },

    // Validates the current user's token and refreshes it
    async validate () {
        await axios.post(`${BASE_URL}${API_ROUTES.AUTH.VALIDATE}`, {}, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
       
        return true;
    },
}

// ===
// Private helpers
// ===

function saveState (key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}
